<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-50" @close="closeFromOverlay">
            <TransitionChild
                v-if="overlay"
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-black/20 backdrop-blur backdrop-filter transition-opacity dark:bg-white/10"
                />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div :class="[positionClasses[position], 'flex min-h-full justify-center']">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            v-bind="$attrs"
                            :class="[sizeClasses[size], 'relative w-full transform overflow-hidden transition-all']"
                        >
                            <slot>
                                <PCard class="p-6">
                                    <PPlaceholder />
                                </PCard>
                            </slot>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { PCard, PPlaceholder } from '@/Components';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
    overlayClose: {
        type: Boolean,
        default: true,
    },
    overlay: {
        type: Boolean,
        default: true,
    },
    position: {
        type: String,
        default: 'middle',
        validator: (value) => ['top', 'middle', 'bottom'].indexOf(value) > -1,
    },
    size: {
        type: String,
        default: 'md',
        validator: (value) => ['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', 'full'].indexOf(value) > -1,
    },
});

const emit = defineEmits(['close']);

const close = () => {
    emit('close');
};

const closeFromOverlay = () => {
    if (props.overlayClose) {
        close();
    }
};

const positionClasses = {
    top: 'items-start',
    middle: 'items-center',
    bottom: 'items-end',
};

const sizeClasses = {
    '2xs': 'max-w-sm',
    xs: 'max-w-md',
    sm: 'max-w-screen-sm',
    md: 'max-w-screen-md',
    lg: 'max-w-screen-lg',
    xl: 'max-w-screen-xl',
    '2xl': 'max-w-screen-2xl',
    full: 'max-w-full',
};
</script>
