<template>
    <div>
        <label>
            <div v-if="label" :class="[{ required: required }, 'form-label']">
                {{ label }}
            </div>
            <div class="form-label-description" v-if="description" v-html="description" />

            <div class="relative">
                <textarea
                    ref="textarea"
                    v-bind="$attrs"
                    rows="4"
                    :class="[{ error: error }, 'form-textarea']"
                    :value="modelValue"
                    @input="$emit('update:modelValue', $event.target.value)"
                />

                <div v-if="error" class="form-trailing-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-4 text-red-500"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                        />
                    </svg>
                </div>
            </div>
            <Error :message="error" class="mt-2" />
        </label>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Error from './Error.vue';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: null,
    },
    label: String,
    error: String,
    required: {
        type: Boolean,
        default: false,
    },
    description: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: 'sm',
        validator: (value) => ['sm', 'md', 'lg'].indexOf(value) > -1,
    },
});

defineEmits(['update:modelValue']);

const textarea = ref(null);

onMounted(() => {
    if (textarea.value.hasAttribute('autofocus')) {
        textarea.value.focus();
    }
});

defineExpose({ focus: () => textarea.value.focus() });
</script>
