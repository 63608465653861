<template>
    <button type="button" :disabled="isDisabled" :class="[{ 'btn-disabled': isDisabled }, variantClass, sizeClass]">
        <div v-if="loading" class="loading-spinner h-5 w-5"></div>
        <slot v-else />
    </button>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    disable: {
        type: Boolean,
        default: false,
    },
    variant: {
        type: String,
        default: 'solid',
        validator: (value) => ['solid', 'outline', 'ghost', 'soft', 'white', 'link', 'neutral'].indexOf(value) > -1,
    },
    size: {
        type: String,
        default: 'sm',
        validator: (value) => ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(value) > -1,
    },
    color: {
        type: String,
        default: 'primary',
        validator: (value) =>
            [
                'primary',
                'secondary',
                'dark-gray',
                'light-gray',
                'red',
                'yellow',
                'green',
                'blue',
                'indigo',
                'purple',
                'pink',
                'white',
            ].indexOf(value) > -1,
    },
    focusOutline: {
        type: Boolean,
        default: true,
    },
});

const isDisabled = computed(() => props.disable || props.loading);
const variantClass = computed(() => {
    if (['white', 'link', 'neutral'].includes(props.variant)) {
        return `btn-${props.variant}`;
    }

    return `btn-${props.variant}-${props.color}`;
});
const sizeClass = computed(() => `btn-${props.size}`);
</script>
