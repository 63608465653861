<template>
    <div
        class="relative h-96 overflow-hidden rounded border border-dashed border-gray-400 opacity-75 dark:border-gray-500"
    >
        <svg class="absolute inset-0 h-full w-full stroke-gray-200 dark:stroke-gray-700" fill="none">
            <defs>
                <pattern
                    id="pattern-bb904d76-8ce0-4b79-8986-92c472e1c066"
                    x="0"
                    y="0"
                    width="10"
                    height="10"
                    patternUnits="userSpaceOnUse"
                >
                    <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
                </pattern>
            </defs>
            <rect
                stroke="none"
                fill="url(#pattern-bb904d76-8ce0-4b79-8986-92c472e1c066)"
                width="100%"
                height="100%"
            ></rect>
        </svg>
    </div>
</template>
