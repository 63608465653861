<template>
    <div>
        <label class="flex items-center">
            <input type="radio" :value="value" v-model="proxyChecked" v-bind="$attrs" class="form-radio" />
            <div v-if="label" :class="[{ required: required }, 'form-radio-label']">
                {{ label }}
            </div>
        </label>
    </div>
</template>

<script setup>
import { computed } from 'vue';

defineOptions({
    inheritAttrs: false,
});

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    label: String,
    modelValue: {
        type: [Array, Boolean],
        required: true,
    },
    value: {
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.modelValue;
    },

    set(val) {
        emit('update:modelValue', val);
    },
});
</script>
